.index {

  @keyframes glow {
    0% {
      box-shadow: 0 0 5px var(--color-red);
    }	

    100% {
      box-shadow: 0 0 20px var(--color-red);
    }
  }

  .container {
    display: grid;

    grid-gap: 48px;

    margin: 60px auto;
  }

  .highlight {
    outline: none;

    animation: glow 1000ms ease-out infinite alternate;
  }

}
